<template>
  <mobile-screen :header="true" screen-class="icon-app1 cockpit-screen gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="cockpit-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_cockpit-settings'
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title" v-if="widgetTypes && widgetTypes.length">
          {{ widgetTypes.find(el => el.type === "VISITOR_MANAGEMENT").name }}
        </div>
        <template v-slot:right
          ><button @click="search = !search">
            <icon icon="#cx-hea1-search" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="searchQuery = ''"
        :simpleSearch="true"
      ></search>
    </template>
    <Draggable
      v-if="listOfReceptionDesks && listOfReceptionDesks.length"
      :list="listOfReceptionDesks"
      item-key="id"
      tag="ul"
      @end="orderItem"
      class="clebex-item-section pill"
    >
      <template #item="{element}">
        <li class="clebex-item-section-item" v-if="element.is_active">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    :id="`settings-visitor-management-${element.id}`"
                    type="checkbox"
                    v-model="element.is_active"
                    @change="assignItem(element.id)"
                  />
                  <label
                    :for="`settings-visitor-management-${element.id}`"
                  ></label>
                </div>
              </dt>
              <dd class="clebex-item-dd">
                <button class="cursor-default">
                  {{ element.name }}
                </button>
              </dd>
            </dl>
          </div>
        </li>
      </template>
    </Draggable>
    <ul
      v-if="listOfReceptionDesks && listOfReceptionDesks.length"
      class="clebex-item-section pill"
    >
      <li
        v-for="receptionDesk in listOfReceptionDesks.filter(
          el => !el.is_active
        )"
        :key="receptionDesk.id"
        class="clebex-item-section-item"
      >
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  :id="`settings-visitor-management-${receptionDesk.id}`"
                  type="checkbox"
                  v-model="receptionDesk.is_active"
                  @change="assignItem(receptionDesk.id)"
                />
                <label
                  :for="`settings-visitor-management-${receptionDesk.id}`"
                ></label>
              </div>
            </dt>
            <dd class="clebex-item-dd">
              <button class="cursor-default">
                {{ receptionDesk.name }}
              </button>
            </dd>
          </dl>
        </div>
      </li>
    </ul>

    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";

export default {
  name: "WidgetSettingsVisitorManagement",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Draggable: defineAsyncComponent(() => import("vuedraggable")),
    Search: defineAsyncComponent(() => import("@/components/search/Search"))
  },
  data() {
    return {
      search: false,
      searchQuery: ""
    };
  },
  created() {
    if (
      !this.widgetTypes ||
      this.widgetTypes.find(el => el.type === "VISITOR_MANAGEMENT") ===
        "undefined"
    ) {
      this.$router.push({
        name: "r_cockpit-settings"
      });
    } else {
      this.getReceptionDesks().then(() => {
        if (
          this.receptionDesks &&
          this.receptionDesks.length &&
          this.widgetItems &&
          this.widgetItems["VISITOR_MANAGEMENT"]
        ) {
          this.setInitialReceptionDesks();
        }
      });
    }
  },
  computed: {
    ...mapState("cockpit", ["widgetTypes", "widgetItems"]),
    ...mapState("visitorManagement", ["receptionDesks"]),
    listOfReceptionDesks() {
      if (this.receptionDesks && this.receptionDesks.length) {
        const query = this.searchQuery;
        if (query && query.length > 1) {
          return this.receptionDesks.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.receptionDesks;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("visitorManagement", ["getReceptionDesks"]),
    ...mapActions("cockpit", ["setActiveWidgetItem", "setWidgetItemsOrder"]),
    setInitialReceptionDesks() {
      this.receptionDesks.forEach(el => {
        const widgetItem = this.widgetItems["VISITOR_MANAGEMENT"].find(
          item => item.itemable.id === el.id
        );
        el.is_active = widgetItem ? true : false;
        el.order = widgetItem ? widgetItem.order : null;
      });
      this.receptionDesks.sort((a, b) => a.order - b.order);
    },
    assignItem(id) {
      this.setActiveWidgetItem({
        itemId: id,
        widgetType: "VISITOR_MANAGEMENT"
      });
    },
    orderItem() {
      const postArray = [];
      let ordering = 1;
      this.receptionDesks.forEach(element => {
        if (element.is_active) {
          postArray.push({
            id: this.widgetItems["VISITOR_MANAGEMENT"].find(
              item => item.itemable.id === element.id
            ).id,
            ordering: ordering
          });
          ordering++;
        }
      });
      this.setWidgetItemsOrder({
        items: postArray,
        widgetType: "VISITOR_MANAGEMENT"
      });
    },
    modifySearchQuery(searchQuery) {
      this.searchQuery = searchQuery;
    }
  },
  watch: {
    widgetItems: {
      deep: true,
      handler(val) {
        if (
          val &&
          val["VISITOR_MANAGEMENT"] &&
          this.receptionDesks &&
          this.receptionDesks.length
        ) {
          this.setInitialReceptionDesks();
        }
      }
    }
  }
};
</script>
